:root {
  --main-bg-color: #DADCE4;
  --accent-color: #8B8FA5;
}
body {
  overflow: hidden;
  color: var(--accent-color);
font-family: 'Nunito', sans-serif;
}

body::-moz-selection
{ 
  background-color: #38FFF2;
  color: #ffffff;
}
body::selection 
{ 
  background-color: #38FFF2; 
  color: #ffffff;
}

p::-moz-selection
{ 
  background-color: #38FFF2;
  color: #ffffff;
}
p::selection 
{ 
  background-color: #38FFF2; 
  color: #ffffff;
}

h1::-moz-selection
{ 
  background-color: #38FFF2;
  color: #ffffff;
}
h1::selection 
{ 
  background-color: #38FFF2; 
  color: #ffffff;
}

.radioText::-moz-selection
{ 
  background-color: #38FFF2;
  color: #ffffff;
}
.radioText::selection 
{ 
  background-color: #38FFF2; 
  color: #ffffff;
}

.copyText::-moz-selection
{ 
  background-color: #38FFF2;
  color: #ffffff;
}
.copyText::selection 
{ 
  background-color: #38FFF2; 
  color: #ffffff;
}
.toolTip::-moz-selection
{ 
  background-color: #38FFF2;
  color: #ffffff;
}
.toolTip::selection 
{ 
  background-color: #38FFF2; 
  color: #ffffff;
}
.button::-moz-selection
{ 
  background-color: #38FFF2;
  color: #ffffff;
}
.button::selection 
{ 
  background-color: #38FFF2; 
  color: #ffffff;
}
.colorInput::-moz-selection
{ 
  background-color: #38FFF2;
  color: #ffffff;
}
.colorInput::selection 
{ 
  background-color: #38FFF2; 
  color: #ffffff;
}

.button {
  border-radius: 0.25em;
  border: 2px solid var(--accent-color);
  line-height: 2em;
  width: 4em;
  color: var(--accent-color);
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  align-items: center;
  text-align: center;
  vertical-align: center;
  cursor: pointer;
}

.buttonNoText {
  border-radius: 0.25em;
  border: 2px solid var(--accent-color);
  height: 2em;
  width: 4.5em;
  color: var(--accent-color);
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  align-items: center;
  text-align: center;
  align-content: center;
  vertical-align: center;
  justify-content: center;
  cursor: pointer;
  display: flex;
}

.paragraph {
color: var(--accent-color);
font-family: 'Nunito', sans-serif;
}

input[type=range] {
  -webkit-appearance: none;
  background: var(--accent-color);
  border-radius: 50px;
  width: 60%;
  height: 8px;
  vertical-align:middle;
  display: inline-block;
  outline: none;

}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: var(--accent-color);
  cursor: pointer;
  border-radius: 50%;
  outline: none;
  color: var(--main-bg-color);
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: var(--accent-color);
  cursor: pointer;
  border-radius: 50%;
  outline: none;
}

.optionsContainer {
border-radius: 50px;
background-color: var(--main-bg-color);
padding: 0.5em 2em 1.5em 2em;
margin-bottom: 1em;
box-shadow: inset 7px 7px 14px #cfd1d4, 
            inset -7px -7px 14px #ffffff;
max-width: 22em;
width: 22em;
height: 36em;
display: grid;

}

.heading {
color: var(--accent-color);
font-family: 'Nunito', sans-serif;
}

.subHeading {
  color: var(--accent-color);
  font-family: 'Nunito', sans-serif;
  padding: 0;
  margin-bottom: 0;
}
.navContainer {

}

.navOption {

}

.colorPresetContainer {
display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1em 1em;
  grid-template-areas: ". . . ." ". . . .";
   padding: 0 0 1em 0;
}

.colorPreset {
  min-height: 2em;
  min-width: 2em;
  height: 2em;
  width: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 5px 10px #cacccf, 
             -5px -5px 10px #ffffff;

  background-color: var(--main-bg-color);
  padding: 1em;
  border-radius: 50%;
  background: linear-gradient(145deg, #ffffff, #d6d8dc);

}

.colorPreset:hover {
  cursor: pointer;
}

.shapePresetOuterContainer {

}

.shapePresetContainer {
display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0.6em 1em;
  grid-template-areas: ". . . .";
  padding: 0 0 0.5em 0;
}

.shapePreset {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 2em;
  min-width: 2em;
  height: 2em;
  width: 2em;
  box-shadow: 5px 5px 10px #cacccf, 
             -5px -5px 10px #ffffff;

  background-color: var(--main-bg-color);
  padding: 1em;
  border-radius: 50%;
  background: linear-gradient(145deg, #ffffff, #d6d8dc);
}

.shapePreset:hover {
  cursor: pointer;
}

.settingsPresetContainer {

}

.settingsPreset {

}

.sunPresetContainer {

}

.sunPreset {

}

.shapeContainer {
display: grid;
grid-template-columns: 1fr 1fr 2 fr;
  grid-template-rows: 1fr;
  gap: 4em 2em;
  grid-template-areas: "a b c c";
  padding: 0 0.5em 0.5em 0;
  margin-top:0.5em;
  justify-content: center;
}

.radioContainer {
  display: flex;
  justify-content: center;
  align-content: center;   
  align-items: center;
  flex-direction: column;
}

.radioText {
padding: 0.25em 0 0 0;
margin: 0;
height: 1em;
}

.shapeOuterContainer {

}

.otherContainer {

}

.exampleContainer {
  border-radius: 2em;
  min-height: 50px;
  min-width: 50px;
  box-shadow: inset 7px 7px 14px #d4d6d9, 
            inset -7px -7px 14px #ffffff;
}

.exampleTextContainer {
  width:30%;
  height:80%;
  resize: none;
  padding: 2em;
  border-radius: 50px;
background-color: var(--main-bg-color);
padding: 0.5em 2em 1em 2em;
box-shadow: inset 7px 7px 14px #cfd1d4, 
            inset -7px -7px 14px #ffffff;
  outline: none;
  border: none;
  position:absolute;
  z-index: 999;
  opacity: 0;
}

 .mockTextArea {
      width:30%;
  height:80%;
  resize: none;
  padding: 2em;
  top: calc(50% - 40vh);
    outline: none;
  border: none;
  position:absolute;
    border-radius: 50px;

  background-color: var(--main-bg-color);
padding: 0.5em 2em 1em 2em;
box-shadow: inset 7px 7px 14px #cfd1d4, 
            inset -7px -7px 14px #ffffff;
  overflow-wrap: break-word;
  }

.column {
max-width: 45%;
min-width: 45%;
min-height: 100vh;
display: flex;
justify-content: center;
flex-direction: column;
align-content: center;
align-items: center;
}

.contentColumnSection {
  background-color: var(--main-bg-color);
  display: flex;
  min-width: 100vw;
  min-height: 100vh;
  padding: 0.5em;
}


.nextButton:hover {
  cursor: pointer;
}

.nextContainer {
  display: flex;
   justify-content: space-between;
   vertical-align: middle;
   align-items: center;


}

.copyPasta {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1em 2em;
  grid-template-areas: ". ." ". .";
  text-align: center;
  border-radius: 0.25em;
  border: 2px solid var(--accent-color);
  padding: 1em 0 1em 0;
  margin: 1em 0 1em 0;
  color: var(--accent-color);
font-family: 'Nunito', sans-serif;
  border-radius: 1em;

}

.toolTip {
  color: var(--accent-color);
  min-width: 40%;
  min-height: 40%;
  border-radius: 0.25em;
  border: 2px solid var(--accent-color);
  text-align: center;
  font-family: 'Nunito', sans-serif;
  line-height: 2em;

}

.copyText {

}

.copyText:hover {
  cursor: pointer;
  color: #3B89FD;
}

.copyText:active {
  color: #38FFF2;
}

.rectIcon1 {
  border-radius: 15%;
   width: 40%;
  height: 80%;
  background-color: #D2D7E2;
}

.rectIcon2 {
  border-radius: 15%;
   width: 40%;
  height: 80%;
  background-color: var(--accent-color);
}

.circleIcon1 {
  border-radius: 50%;
  width: 40%;
  height: 80%;
  background-color: #D2D7E2;
}

.circleIcon2 {
  border-radius: 50%;
   width: 40%;
  height: 80%;
  background-color: var(--accent-color);

}

.innerColor {
  border-radius: 0.125em;
  width: 100%;
  height: 100%;
}

.sliderContainer {
  padding: 0;
  margin: 0;
}

.sliderText {
width: 30%;
display: inline-block;
font-size: 0.85em;
}

.outerSliderContainer {
  padding-top: 0em;
  margin-top: 0em;
}

.optionsHeadingContainer {
display: flex;
justify-content: space-between;
margin-top: 0.5em;
}

.innerSubHeading {
  color: var(--accent-color);
  font-family: 'Nunito', sans-serif;
  padding: 0;
  margin: 0;
}
.innerColorContainer {
vertical-align: middle;
justify-content: space-between;
align-items: center;
display: flex;
width: 100%;
margin-left: 0;
padding: 0;
}

.colorPicker {
  width: 80%;
  margin-right: 0.5em;
}

.colorInput {
  color: var(--accent-color);
font-family: 'Nunito', sans-serif !important;
background-color: var(--main-bg-color);
width: 100%;
max-height: 75% !important;
border: 1px solid var(--accent-color);
border-radius: 0.25em;
}

.colorInput:focus{
    border: 1px solid #0076FF;
}
/*
.colorPicker:focus{
    border: 1px solid #0076FF;
}*/

.colorText {
display: inline-block;
width: 30%;
font-size: 0.85em;
}

.neuText {
    font-family: 'Nunito', sans-serif !important;
    font-weight: 600;
    font-size: 6rem;
    color: #8B8FA5;
    text-shadow: -5px -3px 8px #8B8FA5,
    6px 2px 12px #8B8FA5;
    outline: none;
    width:auto;
    line-height:1;
    pointer-events:none;
    position: relative;
    z-index: 1000;

  }

  .textAreaContainer {
    max-width: 22em;
width: 22em;
height: 36em;
  }

  .ph {
    width: 250px;
    height: 54px;
    position: absolute;
  }
@media only screen and (max-width: 600px) {
.ph {
  width: 125px;
    height: 27px;
}
}

@media only screen and (max-width: 400px) {
.ph {
  width: 112.5px;
    height: 24.3px;
}
}

@media only screen and (max-width: 340px) {
.ph {
  width: 87.5px;
    height: 18.9px;
}
}

@media only screen and (max-width: 1024px) {
  html, body {
  background-color: var(--main-bg-color);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
  .contentColumnSection {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow: hidden;
  min-width: 100vw;
  min-height: 100vh;
  }

  .column {
    min-height: 40vh;
    max-width: 100%;
  }

  .exampleContainer {
    max-width: 225px;
    max-height: 225px;
  }

  .optionsContainer {
    width: 16.5em;
    height: 27em;
  }

  .buttonNoText {
    height: 1.5em;
    width: 3.375em;
  }

  .button {
    line-height: 1.5em;
  width: 3em;
  }
}
